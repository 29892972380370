<template>
  <v-dialog v-model="dialog" persistent width="500">
    <common-card>
      <v-card-text class="pa-4 black--text" style="text-align: center;">
        <div
          class="pt-5 pb-5 top-text text-data-details"
          style="width: 100%; height: 100%; justify-content: center;"
        >
          Motivo de {{ rechazo ? 'rechazo' : 'autorización' }}
        </div>
        <div class="pt-5 pb-5">
          <span class="confirmation-text text-data-details">
            Coloca el motivo por el la redención de esta solicitud es {{ rechazo ? 'rechazada' : 'autorizada' }}.
          </span>
          <v-row class="pt-5">
            <v-col cols="12" md="12">
              <v-text-field
                v-model="motivo"
                label="Motivo"
                outlined
                :rules="[$rules.required]"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
        <div>
          <v-row>
            <v-col cols="12">
              <v-btn
                class="common-botton"
                dark
                @click="userChoice(true)"
                :disabled="!motivo"
                >Guardar</v-btn
              >
            </v-col>
            <v-col cols="12">
              <v-btn
                text
                color="#00a7e4"
                outlined
                dark
                style="border-radius: 15px;"
                @click="userChoice(false)"
              >
                Cancelar</v-btn
              >
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </common-card>
  </v-dialog>
</template>

<script>
import CommonCard from "@/components/commondComponents/CommonCard.vue";
export default {
  components: {
    CommonCard,
  },
  props: {
    dialog: Boolean,
    rechazo: Boolean,
  },

  data() {
    return {
      motivo: "",
    };
  },
  methods: {
    userChoice(val) {
      this.$emit("userChoice", val, this.motivo);
    },
  },
};
</script>

<style>
@import "../../../css/global-styles.css";

.v-toolbar--dense .v-toolbar__content,
.v-toolbar--dense .v-toolbar__extension {
  padding-top: 0;
  padding-bottom: 0;
  justify-content: center;
}

.top-text {
  width: 369px;
  height: 22px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 110%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #4d5358;
  flex: none;
  order: 0;
  flex-grow: 1;
}

.confirmation-text {
  /* Por favor, asegúrate de revisar cuidadosamente los cambios antes de continuar */

  width: 369px;
  height: 30px;

  /* Subtitle/S */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 110%;
  /* or 15px */
  text-align: center;

  color: #555555;

  /* Inside auto layout */
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
}
</style>
